import RunStore from "./runs/RunStore";
import Stats from './stats';

require('../css/app.scss');

require('overlayscrollbars/css/OverlayScrollbars.css');

import moment from "moment";

require("moment/locale/de");
require("moment/locale/de-ch");
require("moment/locale/de-at");
require("moment/locale/it");

import _ from 'underscore';
import SetupChart from "./chart/chart";

require('h-include/lib/h-include');
require('h-include/lib/h-include-extensions');

const UiFeatures = [
    { selector: '.xbar', component: 'xbar' },
    { selector: '.selectables', component: 'selectables' },
    { selector: '.profilechecklist__dialog', component: 'checklist/checklistDialog' },
    { selector: '.collapse-context', component: 'collapse' },
    { selector: '.archi-call', component: 'archi'}
];

(function() {
    var proto = Object.create(HInclude.HIncludeLazyElement.prototype);

    proto.onEnd = function() {
        initDom(this);
        runEmbeddedScripts(this);
        //initStartupFunctions(this);
    }

    var HrInclude = function() {
        return Reflect.construct(HTMLElement, arguments, HrInclude);
    };
    HrInclude.prototype = proto;

    customElements.define('hr-include', HrInclude);
    return HrInclude;
})();

window.moment = moment;


window.UI = {
    init: initDom,
    initScrollbars,
    initDateTimePicker,
    initTabs,
    initTwemoji
};

window.Theme = {};

window.Results = {
    display: (type) => import('./runs/components/' + type).then(({default: C}) => {
        return C;
    })
};

window.addEventListener('load', () => {
   HInclude.initLazyLoad('hr-include');
});

document.addEventListener('DOMContentLoaded', () => {

    const html = document.documentElement;
    const locale = html.getAttribute('lang');
    if (locale) {
        moment.locale(locale);
    }

    initTheme();

    initToTop();
    initSidenav();
    initReloadableHInclude(html);

    initDom(html);
    initStartupFunctions(html);
    loadDemoData(html);

});

function initUiFeatures(root) {
    UiFeatures.forEach(({selector, component}) => {
        const items = [...root.querySelectorAll(selector)];
        if (items.length > 0) {
            import(`./ui/${component}`).then(({default: Component}) => {
                items.forEach((item) => {
                    const component = new Component(item);
                    item[`ui${component}`] = component;
                })
            });
        }
    });
}

function initActions(root) {
    new Stats(root);
}

function initActivator(html) {

    function activate(e) {
        const id = e.currentTarget.dataset.target;
        const el = document.getElementById(id);
        if (el) {
            if (e.currentTarget.classList.contains('set-active-toggle')) {
                if (el.classList.contains('is-active')) {
                    el.classList.remove('is-active');
                } else {
                    el.classList.add('is-active');
                }
            } else {
                el.classList.add('is-active');
            }
        }

    }

    function close(e) {
        const el = e.currentTarget.closest('.is-active');
        if (el) { el.classList.remove('is-active'); }

        if (e.currentTarget.classList.contains('set-active-toggle')) {
            e.currentTarget.classList.remove('set-inactive');
            e.currentTarget.classList.add('set-active');
        }
    }

    const activators = html.querySelectorAll('.set-active');
    [...activators].forEach((item) => { item.addEventListener('click', activate); });


    const closer = html.querySelectorAll('.set-inactive');
    [...closer].forEach((item) => { item.addEventListener('click', close); });
}

function initTheme() {
    const helper = document.querySelector('#color-helper');

    if (helper && window?.getComputedStyle) {
        const style  = window.getComputedStyle(helper);

        window.Theme.primary = style.color;
        window.Theme.fontFamily = style.fontFamily;
        window.Theme.fontWeight = 300; //style.fontWeight;
    }
}

function initEditors(root) {
    const editors = root.querySelectorAll('.add-markdown-editor textarea');
    if (editors.length > 0) {


    }
}

function loadDemoData(root) {
    const demoFields = root.querySelectorAll('.set-demo-value');

    if (demoFields.length > 0) {
        import("./runs/RunStore").then(({default: RunStore}) => {
            const demoData = RunStore.get('demo');

            demoFields.forEach((input) => {
                const key = input.dataset.demo;
                input.dataset.demo = key;

                if (demoData.hasOwnProperty(key)) {
                    input.value = demoData[key];
                }

                input.addEventListener('change', updateDemoData);
            });

            function updateDemoData(e) {
                if (e.target.dataset.demo) {
                    demoData[e.target.dataset.demo] = e.target.value;
                    RunStore.set('demo', demoData);
                }
            }

        });
    }
}

function runEmbeddedScripts(item) {
    const scripts = item.querySelectorAll('script');
    scripts.forEach((script) => {
        const runner = document.createElement('script');
        runner.textContent = script.innerText;
        document.head.appendChild(runner);
    });
}

function initStartupFunctions(root) {
    if (!root) {
        root = document.documentElement;
    }
    const init = root.querySelectorAll('*[data-init-fn]');
    init.forEach((item) => {
        const fnName = item.dataset.initFn;
        try {
            window[fnName](item);
        } catch (e) {
            console.warn("Init Failed", fnName, item, e);
        }
    })

}

function initDom(root) {
    if (!root) {
        root = document.documentElement;
    }

    loadIcons(root);

    initCharts(root);
    initTwemoji(root);
    initScrollbars(root);
    initDateTimePicker(root);
    initTabs(root);

    initFormOptions(root);

    initDemoValues(root);

    initEditors(root);

    initUiFeatures(root);

    initActions(root);

    initActivator(root);
}

function initDemoValues(root) {

    const demoContainer = root.querySelectorAll('*[data-demodatakey]');
    if (demoContainer.length > 0) {
        import('./runs/RunStore').then(({default: RunStore}) => {
            const demoData = RunStore.get('demo');
            demoContainer.forEach((item) => {
                const demoName = item.dataset.demodatakey;
                if (demoData[demoName]) {
                    item.value = demoData[demoName];
                }
            });
        });
    }

}

function initFormOptions(root) {

    // form-target changer
    const changer = root.querySelectorAll('a[data-form-target], button[data-form-target]');
    changer.forEach((item) => {
        item.addEventListener('click', changeFormAction);
    });

    // dropdown
    const setDropDownLabelItems = root.querySelectorAll('.dropdown.set-label-from-item .dropdown-item');
    setDropDownLabelItems.forEach((item) => {
        item.addEventListener('click', setDropDownLabel);
    });
}
function setDropDownLabel(e) {
    e.preventDefault();
    const dd = e.target.closest('.dropdown');
    const label = dd.querySelector('.dd-label');
    label.innerText = e.target.dataset.label || e.target.innerText;
}
function changeFormAction(e) {
    e.preventDefault();
    const form = e.target.closest('form');
    const url = e.target.dataset.formTarget;
    form.setAttribute('action', url);
}

const loadedIcons = [];

async function loadIcons(root) {
    const icons = {
        '.runquestion--type-plusbox input[type="radio"] + .runquestion__label': '/images/status/plus.svg',
        '.runfeedback--type-plusbox .runfeedback__choice span': '/images/status/plus.svg',
        '.runresultbox__result--type-PlusBox .runresultbox__label span': '/images/status/plus.svg',
    };
    let toLoad = Object.keys(icons).length;
    for (let key in icons) {
        if (icons.hasOwnProperty(key) && document.querySelector(key)) {
            load(key);
        }
    }

    function gotOne() {
        toLoad--;
        if (toLoad === 0) {
            replace();
        }
    }

    function load(key) {
        const url = icons[key];

        if (loadedIcons.includes(url)) {
            gotOne();
            return;
        }

        fetch(icons[key])
        .then((response) => response.text())
        .then((svg) => {
            icons[key] = svg;
            loadedIcons.push(url);
            gotOne();
        })
    }

    function replace() {

        for (let selector in icons) {
            if (icons.hasOwnProperty(selector)) {
                const items = root.querySelectorAll(selector);
                items.forEach((item) => {
                    setIcon(item, selector);
                });
            }
        }
    }

    function setIcon(item, value) {
        if (icons.hasOwnProperty(value)) {
            item.innerHTML = '';
            item.insertAdjacentHTML("afterbegin", icons[value]);
        }
    }
}

function initReloadableHInclude() {
    // const hincludes = document.querySelectorAll('h-include-lazy.reloadable, h-include.reloadable');
    // if (hincludes.length > 0) {
    //
    //     const reloader = document.createElement('div');
    //     reloader.classList.add('h-include__reloader');
    //
    //     hincludes.forEach((item) => {
    //
    //         const reloader = item.cloneNode()
    //
    //     });
    // }
}

function initToTop() {
    const item = document.getElementById('totop');

    if (item) {
        const a    = item.querySelector('a');

        a.addEventListener('click', function(e) {
            e.preventDefault();
            window.scrollTo(0, 0);
        })

        window.addEventListener('scroll', debounce(() => {
            const show = window.scrollY > 200;
            if (show) {
                item.classList.add('shown');
            } else {
                item.classList.remove('shown');
            }
        }, 200));
    }
}

function initCharts(root) {
    const charts = root.querySelectorAll('.chartjs');
    if (charts && charts.length > 0) {
        import('chart.js').then(({default: Chart}) => {
            import('chartjs-chart-box-and-violin-plot').then(() => {
                import('chartjs-plugin-trendline').then(({default: T}) => {
                    charts.forEach((item) => {
                        const fn = item.dataset.fn;
                        const version = parseInt(item.dataset.version, 10);
                        if (fn && window[fn] && typeof window[fn] === 'function') {
                            if (version > 1) {
                                import('./chart/chart').then(({default: SetupChart}) => {
                                    SetupChart(item, Chart, window[fn]);
                                });
                            } else {
                                window[fn].call(null, Chart);
                            }
                        }
                    });
                });
            });
        });
    }
}

function initTabs(root) {
    const tabs = root.querySelectorAll('.tabs[data-for]');
    if (tabs.length === 0) { return; }

    tabs.forEach((tab) => {
        const container = document.getElementById(tab.dataset.for);
        if (!container) { return; }

        const blocks = container.querySelectorAll('.tabs__block');
        const links = tab.querySelectorAll('li > a');

        blocks.forEach((b) => b.classList.add('is-hidden'));

        let current = links[0];
        let currentBlock = container.querySelector(current.getAttribute('href'));
        currentBlock.classList.remove('is-hidden');

        tab.addEventListener('click', (e) => {
            e.preventDefault();
            const target = e.target.getAttribute('href');
            if (target) {
                current.closest('li').classList.remove('is-active');
                currentBlock.classList.add('is-hidden');

                current = e.target;
                currentBlock =  container.querySelector(current.getAttribute('href'));
                current.closest('li').classList.add('is-active');
                currentBlock.classList.remove('is-hidden');
            }
        });
    });
}

function initDateTimePicker(root) {

    const date = root.querySelectorAll('.datepicker input');
    const time = root.querySelectorAll('.timepicker input');
    if (date.length > 0 || time.length > 0) {
        return new Promise((resolve, reject) => {
            import('../css/tail.datetime.less')
            .then(() => import('tail.datetime'))
            .then(({default: DateTime}) => {

                const locale = document.documentElement.getAttribute('lang') || 'de';
                const datePickers = [];
                const timePickers = [];

                date.forEach((item) => {
                    datePickers.push(DateTime(item, {
                        position: 'top',
                        locale: locale,
                        timeFormat: false,
                        dateFormat: "dd.mm.YYYY",
                        weekStart: 1
                    }));
                });

                time.forEach((item) => {
                    const timePicker = DateTime(item, {
                        position: 'top',
                        locale: locale,
                        dateFormat: false,
                        time12h: false,
                        timeFormat: "HH:ii",
                        timeSeconds: false
                    });
                    timePickers.push(timePicker);
                });

                resolve({
                    date: datePickers,
                    time: timePickers
                });
            });
        });
    }
    return null;
}

function initTwemoji(root) {
    const container = root.querySelectorAll('.emoji-container');
    if (container.length > 0) {
        import('twemoji').then(({default: T}) => {
            container.forEach((c) => T.parse(c, {
                base: '/emoji/'
            }));
        });
    }
}

function initSidenav() {
    // only one per page
    const sideNav = document.querySelector('.sidenav');

    if (!sideNav) { return; }

    const sideNavTrigger = document.querySelector('.sidenav-trigger');
    const sideNavBg = document.createElement('div');
    sideNavBg.classList.add('sidenav-bg');

    document.documentElement.appendChild(sideNavBg);

    const toggle = (e) => {
        if (e) { e.preventDefault(); }
        document.documentElement.classList.toggle('sidenav-open');
    };

    sideNavTrigger.addEventListener('click', toggle);
    sideNavBg.addEventListener('click', toggle);
}

function initScrollbars(root) {
    const containers = root.querySelectorAll('.modern-scroll, .scrollable-list');

    if (containers.length > 0) {
        import('overlayscrollbars').then(({default: OverlayScrollbars}) => {
            containers.forEach((item) => {
                const instance = OverlayScrollbars(item, {
                    className: 'os-theme-dark hr-scrollbars',
                    overflowBehavior : {
                        x : item.dataset.scrollX || "scroll",
                        y : item.dataset.scrollY || "scroll",
                    },
                    scrollbars : {
                        visibility: "auto",
                        autoHide: item.dataset.autoHide || "n"
                    }
                });
                item.classList.add('initialized');
            });
        });
    }
}
// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};
