async function fetchDataset(options, name) {

    const fetchOptions = options.fetch ?? {};
    const body = {
        ...(fetchOptions?.messageTemplate ?? {})
    };

    if (fetchOptions.method !== 'POST') {
        throw "Unsupported Method";
    }

    const dataset = options.datasets[name];
    if (!dataset) {
        throw "Unknown dataset: " + name;
    }

    Object.keys(fetchOptions.messageFields).forEach((key) => {
        body[key] = dataset[fetchOptions.messageFields[key]];
    });

    const response = await fetch(fetchOptions.endpoint ?? document.location.href, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(body)
    });

    return response.json();
}

export default function SetupChart(container, Chart, callback) {

    const colors = [
        getComputedStyle(document.querySelector('#color-helper')).color, // primary
        "#a6cee3",
        "#1f78b4",
        "#b2df8a",
        "#33a02c",
        "#fb9a99",
        "#e31a1c",
        "#fdbf6f",
        "#ff7f00",
        "#cab2d6",
        "#6a3d9a",
        "#ffff99",
        "#b15928"
    ];

    const transparentColors = [
        getComputedStyle(document.querySelector('#color-helper')).color, // primary
        "#a6cee3be",
        "#1f78b4be",
        "#b2df8abe",
        "#33a02cbe",
        "#fb9a99be",
        "#e31a1cbe",
        "#fdbf6fbe",
        "#ff7f00be",
        "#cab2d6be",
        "#6a3d9abe",
        "#ffff99be",
        "#b15928be"
    ];


    let ctx = container.getContext('2d');
    let chart = null;
    let chartOptions = callback({getChart, loadDataset, action});
    let currentData = null;

    let chartMin = 100;
    let chartMax = 0;
    let showLabelData = true;

    chart = new Chart(ctx, chartOptions.chartjs);

    if (typeof chartOptions?.ready === "function") {
        chartOptions.ready();
    }

    function getChart() { return chart; }

    /*


                document.getElementById('chart<?php echo $fnName; ?>toggle').addEventListener('click', function(e) {

                    if (this.classList.toggle('is-inverted')) {
                        chart.options.scales.yAxes[0].ticks.beginAtZero = true;
                        chart.options.scales.yAxes[0].ticks.min = 0;
                        chart.options.scales.yAxes[0].ticks.max = 100;
                    } else {
                        chart.options.scales.yAxes[0].ticks.beginAtZero = false;
                        chart.options.scales.yAxes[0].ticks.min = chartZoomMin[currentType];
                        chart.options.scales.yAxes[0].ticks.max = chartZoomMax[currentType];
                    }

                    chart.update();

                });

     */

    function toggleLabelDataView() {
        showLabelData = !showLabelData;
        for (let i = 1; i < chart.data.datasets.length; i++) {
            chart.data.datasets[i].hidden = !showLabelData;
        }
        chart.update();
    }

    function zoomYAxis(source) {
        if (chart.options.scales.yAxes[0].ticks.beginAtZero) {
            chart.options.scales.yAxes[0].ticks.beginAtZero = false;
            chart.options.scales.yAxes[0].ticks.min = chartMin;
            chart.options.scales.yAxes[0].ticks.max = chartMax;

        } else {
            chart.options.scales.yAxes[0].ticks.beginAtZero = true;
            chart.options.scales.yAxes[0].ticks.min = 0;
            chart.options.scales.yAxes[0].ticks.max = 100;
        }

        chart.update();
    }

    function action(action, source) {
        switch (action) {
            case 'zoom-y-axis':
                zoomYAxis(source);
                return true;
            case 'toggle-avg':
                toggleLabelDataView();
                return true;
            case 'load-dataset':
                loadDataset(source.dataset.dataset);
                return true;
        }
    }

    async function loadDataset(name) {
        let data = await fetchDataset(chartOptions, name);

        if (chartOptions.transform) {
            data = chartOptions.transform(data);
        }

        setData(data);
    }

    function setData(data) {
        currentData = data;

        chart.data.labels = getLabels(data);
        chart.data.datasets = prepareData(data);
        chart.update();

    }

    function getLabels(data) {
        return Object.keys(data.data);
    }

    function prepareData(data) {
        chartMin = 100;
        chartMax = 0;

        const sortedTags = [...data.tags].sort();

        const dataByTag = sortedTags.reduce((result, tag) => {
            return {
                ...result,
                [tag]: []
            };
        }, {});

        const avgValues = [];

        Object.keys(data.data).forEach((group) => {
            const groupData = data.data[group];
            const tags = new Set();
            const avg = [];

            groupData.forEach((row) => {
                tags.add(row.tag);
                dataByTag[row.tag].push(row.value);

                chartMin = Math.min(chartMin, row.value);
                chartMax = Math.max(chartMax, row.value);
                avg.push([row.value, row.count]);
            });

            avgValues.push(avg);

            sortedTags.forEach((tag) => {
                if (!tags.has(tag)) {
                    dataByTag[tag].push(null);
                }
            });
        });

        const avgItem = {
            label: 'AVG',
            data: avgValues.reduce((avg, values) => {
                const filteredValues = values.filter((v) => v !== null);
                if (filteredValues.length === 0) {
                    return [...avg, 0];
                } else {
                    const [sum, count] = filteredValues.reduce(([s, tc], [v, c]) => [s + (v * c), tc + c], [0,0]);
                    return [...avg, Math.round((sum / count) * 10) / 10];
                }
            }, []),
            borderColor: colors[0],
            trendlineLinear: {
                style: 'black',
                lineStyle: "dotted",
                width: 2
            }
        }

        const graphData = [
            avgItem,
            ...sortedTags.map((tag, idx) => {
                const c = colors[(idx + 1) % colors.length];
                const label = tag.length > 16 ? tag.substring(0, 16) : tag;
                return {
                    label: label,
                    data: dataByTag[tag],
                    borderColor: c,
                    backgroundColor: c,
                    hoverBorderColor: c,
                    hoverBackgroundColor: c,
                    pointHoverBackgroundColor: c,
                    hidden: !showLabelData,
                    fill: false,
                    borderWidth: 1,
                    hoverBorderWidth: 3
                };
            })
        ];

        return graphData;
    }


}
